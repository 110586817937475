<template>
  <div class="page page-project-view">
    <div class="fit v-pa-md">
      <div class="v-mx-auto" style="max-width: 1000px">
        <h2 class="text-center text-primary">项目详情</h2>
        <div class="v-pa-lg v-my-lg">
          <a-steps progress-dot :current="currentStep" size="small" @change="onChange">
            <a-step title="提交项目意向" />
            <a-step title="千禾评审" />
            <a-step title="提交项目申请书" />
            <a-step title="签订资助协议" />
            <a-step title="项目资助" />
            <a-step title="项目结项" />
            <a-step title="完成" />
          </a-steps>
        </div>
      </div>
      <div class="stage-wrapper v-mx-auto" style="max-width: 1300px">
        <div class="stage-section done">
          <div class="stage-header">
            <i class="fa fa-paper-plane-o"></i>
            <span>项目意向</span>
          </div>
          <div class="stage-data ml-auto">
            <div class="table-container">
              <table class="table table-plain">
                <tbody>
                  <tr>
                    <th width="150">所属版块</th>
                    <td width="200">{{ data.subject }}</td>
                    <th width="150">议题名称</th>
                    <td>{{ data.topic }}</td>
                  </tr>
                  <tr>
                    <th>提交时间</th>
                    <td colspan="3">{{ data.applyTime }}</td>
                  </tr>
                  <tr>
                    <th valign="top">项目概述</th>
                    <td colspan="3">{{ data.summary }}</td>
                  </tr>
                  <tr>
                    <th>相关文件</th>
                    <td colspan="3">
                      <file-container :files="data.$201Files" :belongId="id" fileSign="P01" v-if="data.$201Files && id"></file-container>
                      <!-- <div class="file-list" v-if="data.$201Files">
                        <div class="file-item" v-for="file in data.$201Files" :key="file.id">
                          <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx', 'wps'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="file.fileFormat === 'xls' || file.fileFormat === 'xlsx'" />
                          <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="file.fileFormat === 'ppt' || file.fileFormat === 'pptx'" />
                          <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', '7z'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_img.svg" v-else-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
                          <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
                          <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
                          <span class="text-indigo hover-line" @click="downloadFile({ fileId: file.id, belongId: id, fileSign: 'P01', fileName: file.fileOriginName })">{{ file.fileOriginName }}</span>
                        </div>
                      </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="stage-section" :class="{ done: currentStep > 1 }" v-if="currentStep >= 1">
          <div class="stage-header" :class="{ 'text-red': currentStep === 1 }">
            <i class="fa fa-podcast"></i>
            <span>千禾审核</span>
          </div>
          <div class="stage-data ml-auto">
            <div class="fit flex items-center justify-center" v-if="currentStep === 1">
              <span class="font-lg text-red">
                <i class="fa fa-hourglass-2 fa-pulse-2 v-mr-md"></i>
                千禾正在审核中...
              </span>
            </div>
            <div class="tab-container" v-else>
              <table class="table table-plain">
                <tbody>
                  <tr>
                    <th width="150">审核时间</th>
                    <td>{{ data.reviewTime }}</td>
                  </tr>
                  <tr>
                    <th>审核结果</th>
                    <td>
                      <span class="text-red" v-if="data.reviewResult === 1">通过</span>
                      <span class="text-red" v-else-if="data.reviewResult === 0">未通过</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="stage-section" :class="{ done: currentStep > 2 }" v-if="currentStep >= 2">
          <div class="absolute" style="top: 0; left: 0" v-if="data.stateNode === 4">
            <img src="~@/assets/images/check.png" alt="待审核" />
          </div>
          <div class="stage-header" :class="{ 'text-red': currentStep === 2 }">
            <i class="fa fa-inbox"></i>
            <span>项目申请书</span>
          </div>
          <div class="stage-data ml-auto">
            <div class="table-container" v-if="currentStep > 2 || data.stateNode === 4">
              <table class="table table-plain">
                <tbody>
                  <tr>
                    <th width="150">项目名称</th>
                    <td colspan="3">{{ data.projectName }}</td>
                  </tr>
                  <tr>
                    <th>项目周期</th>
                    <td width="36%">{{ data.startDate }} ~ {{ data.endDate }}</td>
                    <th width="150">拟申请金额（元）</th>
                    <td>{{ $utils.thousands(data.applyAmount, 2) }}</td>
                  </tr>
                  <tr>
                    <th>项目负责人</th>
                    <td>{{ data.supervisor }}</td>
                    <th>项目负责人职位</th>
                    <td>{{ data.supervisorPost }}</td>
                  </tr>
                  <tr>
                    <th>联系手机</th>
                    <td>{{ data.supervisorPhone }}</td>
                    <th>联系邮箱</th>
                    <td>{{ data.supervisorEmail }}</td>
                  </tr>
                  <tr>
                    <th>项目实施地点</th>
                    <td colspan="3">
                      <span>{{ $utils.flatAddress(data.addresses) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th valign="top">项目介绍</th>
                    <td colspan="3">
                      <div class="text-justify text-ellipsis" :class="{ 'ellipsis-15-lines': !showAllContent }" v-html="$utils.convertToHtml(data.content)"></div>
                      <div class="v-mt-xs" v-if="data.content">
                        <span class="text-indigo hover-line" @click="showAllContent = !showAllContent" v-if="!showAllContent">
                          <a-icon type="down" />
                          查看更多
                        </span>
                        <span class="text-indigo hover-line" @click="showAllContent = !showAllContent" v-else>
                          <a-icon type="up" />
                          收起内容
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>项目申请书/预算表</th>
                    <td colspan="3">
                      <file-container :files="data.$202Files" :belongId="data.id" fileSign="P01" v-if="data.$202Files && data.id"></file-container>
                      <!-- <div class="file-list" v-if="data.$202Files">
                        <div class="file-item" v-for="file in data.$202Files" :key="file.id">
                          <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx', 'wps'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="file.fileFormat === 'xls' || file.fileFormat === 'xlsx'" />
                          <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="file.fileFormat === 'ppt' || file.fileFormat === 'pptx'" />
                          <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', '7z'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_img.svg" v-else-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
                          <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
                          <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(file.fileFormat)" />
                          <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
                          <span class="text-indigo hover-line" @click="downloadFile({ fileId: file.id, belongId: id, fileSign: 'P01', fileName: file.fileOriginName })">{{ file.fileOriginName }}</span>
                        </div>
                      </div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-center fit flex items-center justify-center" v-else>
              <a-button type="primary" icon="arrow-right" @click="$router.push(`declare?id=${data.id}`)">去填写项目申请书</a-button>
            </div>
          </div>
        </div>
        <div class="stage-section" :class="{ done: currentStep > 3 }" v-if="currentStep >= 3">
          <div class="stage-header">
            <i class="fa fa-handshake-o"></i>
            <span>资助协议</span>
          </div>
          <div class="stage-data ml-auto">
            <div class="fit flex items-center justify-center" v-if="currentStep === 3">
              <span class="font-lg text-red">
                <i class="fa fa-hourglass-2 fa-pulse-2 v-mr-md"></i>
                资助协议签订中...
              </span>
            </div>
            <div class="table-container" v-else>
              <table class="table table-plain">
                <tbody>
                  <tr>
                    <th width="150">协议编号</th>
                    <td width="36%">
                      <span v-if="data.contract">{{ data.contract.billNo }}</span>
                    </td>
                    <th width="150">资助总额（元）</th>
                    <td>
                      <span v-if="data.contract">{{ $utils.thousands(data.contract.totalAmount) }}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>已收款金额（元）</th>
                    <td><span v-if="data.contract"></span></td>
                    <th>未收款金额（元）</th>
                    <td><span v-if="data.contract"></span></td>
                  </tr>
                  <tr>
                    <th valign="top">协议期限</th>
                    <td colspan="3">
                      <span v-if="data.contract">{{ data.contract.term }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="stage-section" :class="{ done: currentStep > 4 }" v-if="currentStep >= 4">
          <div class="stage-header">
            <i class="fa fa-cogs"></i>
            <span>项目资助</span>
          </div>
          <div class="stage-data ml-auto">
            <div class="table-container">
              <a-tabs class="v-mt-xs tab-dense" type="card" v-model="fundedTab" v-if="currentStep === 4">
                <a-tab-pane key="unload" tab="未提交文档"></a-tab-pane>
                <a-tab-pane key="loaded" tab="已提交文档"></a-tab-pane>
                <a-tab-pane key="changeinfo" tab="项目变更"></a-tab-pane>
                <a-space slot="tabBarExtraContent" v-if="fundedTab === 'changeinfo'">
                  <a-button class="v-mr-sm" size="small" type="primary" icon="plus" @click="handleOpenChangeModal">项目变更申请</a-button>
                </a-space>
              </a-tabs>
              <a-tabs class="v-mt-xs tab-dense" type="card" v-model="fundedTab" v-else>
                <a-tab-pane key="loaded" tab="已提交文档"></a-tab-pane>
                <a-tab-pane key="unload" tab="未提交文档"></a-tab-pane>
                <a-tab-pane key="changeinfo" tab="项目变更"></a-tab-pane>
              </a-tabs>
              <div class="tab-content" v-if="fundedTab === 'unload'">
                <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="reportLoading" :columns="reportCols1" :data-source="reportList1" :scroll="{ x: '100%' }" rowKey="id">
                  <template slot="action" slot-scope="text, record">
                    <span class="text-indigo font-xs hover-line" title="保存" @click="handleSaveReport(record, index)" v-if="record.state === 1">审核</span>
                  </template>
                  <template slot="reportDirection" slot-scope="text, record">
                    <span v-if="record.reportDirection === 1">{{ record.applicant ? record.applicant.realName : '' }}</span>
                    <span v-else-if="record.reportDirection === 2">千禾基金</span>
                  </template>
                  <template slot="rest" slot-scope="text, record">
                    <span class="text-red" v-if="record.restDays < 0">
                      逾期
                      <b>{{ -record.restDays }}天</b>
                    </span>
                    <span class="text-red" v-else-if="record.restDays == 0">明日逾期</span>
                    <span v-else-if="record.restDays > 0">
                      剩余
                      <b class="text-indigo">{{ record.restDays }}</b>
                      天
                    </span>
                  </template>
                  <template slot="state" slot-scope="text, record">
                    <span class="text-grey" v-if="record.state === 0">未上传</span>
                    <span class="text-red" v-else-if="record.state === 1">已上传待审核</span>
                    <span class="text-red" v-else-if="record.state === -1">未通过审核</span>
                    <!-- <span class="text-red" v-else-if="record.state === -1">审核未通过</span> -->
                  </template>
                  <template slot="action" slot-scope="text, record">
                    <span class="text-indigo hover-line" @click="showReportModal(record)" v-if="record.state === 0">去上传</span>
                    <span class="text-indigo hover-line" @click="showReportModal(record)" v-else-if="record.state === 1">查看</span>
                    <span class="text-indigo hover-line" @click="showReportModal(record)" v-else-if="record.state === -1">重新上传</span>
                  </template>
                </a-table>
              </div>
              <div class="tab-content" v-if="fundedTab === 'loaded'">
                <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="reportLoading" :columns="reportCols2" :data-source="reportList2" :scroll="{ x: '100%' }" rowKey="id">
                  <template slot="files" slot-scope="text, record">
                    <file-container :files="record.files" :belongId="id" fileSign="P01" v-if="record.files && id"></file-container>
                    <!-- <div class="file-list" v-if="record.files">
                      <div class="file-item" v-for="file in record.files" :key="file.id">
                        <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx', 'wps'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="file.fileFormat === 'xls' || file.fileFormat === 'xlsx'" />
                        <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="file.fileFormat === 'ppt' || file.fileFormat === 'pptx'" />
                        <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', '7z'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_img.svg" v-else-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
                        <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
                        <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
                        <span class="text-indigo hover-line" @click="downloadFile({ fileId: file.id, belongId: id, fileSign: 'P01', fileName: file.fileOriginName })">{{ file.fileOriginName }}</span>
                      </div>
                    </div> -->
                  </template>
                </a-table>
              </div>
              <div class="tab-content" v-if="fundedTab === 'changeinfo'">
                <template v-if="changeList.length > 0">
                  <a-spin :spinning="changeLoading">
                    <div class="changeinfo-container v-px-xs">
                      <div class="table-container table-changeinfo v-mb-xs" v-for="(item, index) in changeList" :key="item.id" :class="{ 'v-mt-sm': index > 0 }">
                        <table class="table table-plain">
                          <tbody>
                            <tr>
                              <th width="150">申请时间</th>
                              <td>
                                <span>{{ item.createTime ? item.createTime.substring(0, 16) : '' }}</span>
                              </td>
                              <th width="150">变更单状态</th>
                              <td width="200">
                                <div class="flex items-center" v-if="item.state === 0">
                                  <span class="text-grey">初稿</span>
                                  <span class="text-indigo hover-line v-ml-auto" @click="handleEditChangeinfo(item)" v-if="currentStep === 4">
                                    <a-icon type="form" />
                                    编辑
                                  </span>
                                </div>
                                <span class="text-red" v-else-if="item.state === 1">变更申请中</span>
                                <span class="text-indigo" v-else-if="item.state === 2">申请已通过</span>
                                <!-- <span class="text-red" v-else-if="item.state === -1">申请未通过</span> -->
                                <div class="flex items-center" v-else-if="item.state === -1">
                                  <span class="text-red">申请未通过</span>
                                  <span class="text-indigo hover-line v-ml-auto" @click="handleEditChangeinfo(item)" v-if="currentStep === 4">
                                    <a-icon type="form" />
                                    编辑
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th valign="top">变更原因</th>
                              <td colspan="3">
                                <span>{{ item.changeReason }}</span>
                              </td>
                            </tr>
                            <tr>
                              <th valign="top">项目变更明细</th>
                              <td colspan="3" style="padding: 4px !important">
                                <a-table class="list-table" size="small" :bordered="false" :pagination="false" :columns="changeCols" :data-source="item.changeDetails" rowKey="seq">
                                  <template slot="before" slot-scope="text, record">
                                    <span v-if="record.changeType === '项目名称'">{{ record.before.projectName }}</span>
                                    <span v-else-if="record.changeType === '项目负责人'">{{ record.before.supervisor }}</span>
                                    <span v-else-if="record.changeType === '项目预算'">{{ $utils.thousands(record.before.applyAmount, 0) }}</span>
                                    <span v-else-if="record.changeType === '项目周期'">{{ record.before.startDate }} ~ {{ record.before.endDate }}</span>
                                    <span v-else-if="record.changeType === '项目实施地点'">{{ $utils.flatAddress(record.before.addresses) }}</span>
                                  </template>
                                  <template slot="after" slot-scope="text, record">
                                    <span v-if="record.changeType === '项目名称'">{{ record.after.projectName }}</span>
                                    <span v-else-if="record.changeType === '项目负责人'">{{ record.after.supervisor }}</span>
                                    <span v-else-if="record.changeType === '项目预算'">{{ $utils.thousands(record.after.applyAmount, 0) }}</span>
                                    <span v-else-if="record.changeType === '项目周期'">{{ record.after.startDate }} ~ {{ record.after.endDate }}</span>
                                    <span v-else-if="record.changeType === '项目实施地点'">{{ $utils.flatAddress(record.after.addresses) }}</span>
                                  </template>
                                </a-table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </a-spin>
                </template>
                <div style="padding: 10px 0" v-else>
                  <a-empty :image="simpleImage"></a-empty>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage-section" :class="{ done: currentStep > 5 }" v-if="currentStep >= 5">
          <div class="stage-header">
            <i class="fa fa-calendar-check-o"></i>
            <span>项目结项</span>
          </div>
          <div class="stage-data ml-auto">
            <div class="table-container">
              <a-tabs class="v-mt-xs tab-dense" type="card" v-model="concludeTab" v-if="currentStep === 5">
                <a-tab-pane key="unload" tab="未提交文档"></a-tab-pane>
                <a-tab-pane key="loaded" tab="已提交文档"></a-tab-pane>
              </a-tabs>
              <a-tabs class="v-mt-xs tab-dense" type="card" v-model="concludeTab" v-else>
                <a-tab-pane key="loaded" tab="已提交文档"></a-tab-pane>
                <a-tab-pane key="unload" tab="未提交文档"></a-tab-pane>
              </a-tabs>
              <div class="tab-content" v-if="concludeTab === 'unload'">
                <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="reportLoading2" :columns="reportCols1" :data-source="unloadConcludeReportList" :scroll="{ x: '100%' }" rowKey="id">
                  <template slot="action" slot-scope="text, record">
                    <span class="text-indigo hover-line" @click="showReportModal(record)" v-if="record.state === 0">去上传</span>
                    <span class="text-indigo hover-line" @click="showReportModal(record)" v-else-if="record.state === 1">查看</span>
                    <span class="text-indigo hover-line" @click="showReportModal(record)" v-else-if="record.state === -1">重新上传</span>
                  </template>
                  <template slot="reportDirection" slot-scope="text, record">
                    <span v-if="record.reportDirection === 1">{{ data.creatorName }}</span>
                    <span v-else-if="record.reportDirection === 2">千禾基金</span>
                  </template>
                  <template slot="rest" slot-scope="text, record">
                    <span class="text-red" v-if="record.restDays < 0">
                      逾期
                      <b>{{ -record.restDays }}天</b>
                    </span>
                    <span class="text-red" v-else-if="record.restDays == 0">明日逾期</span>
                    <span v-else-if="record.restDays > 0">
                      剩余
                      <b class="text-indigo">{{ record.restDays }}</b>
                      天
                    </span>
                  </template>
                  <template slot="state" slot-scope="text, record">
                    <span class="text-grey" v-if="record.state === 0">未上传</span>
                    <span class="text-red" v-else-if="record.state === 1">待审核</span>
                    <span class="text-red" v-else-if="record.state === -1">审核未通过</span>
                  </template>
                </a-table>
              </div>
              <div class="tab-content" v-if="concludeTab === 'loaded'">
                <a-table class="list-table" size="small" :bordered="false" :pagination="false" :loading="reportLoading2" :columns="reportCols2" :data-source="uploadedConcludeReportList" :scroll="{ x: '100%' }" rowKey="id">
                  <template slot="files" slot-scope="text, record">
                    <file-container :files="record.files" :belongId="id" fileSign="P01" v-if="record.files && id"></file-container>
                    <!-- <div class="file-list" v-if="record.files">
                      <div class="file-item" v-for="file in record.files" :key="file.id">
                        <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx', 'wps'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="file.fileFormat === 'xls' || file.fileFormat === 'xlsx'" />
                        <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="file.fileFormat === 'ppt' || file.fileFormat === 'pptx'" />
                        <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', '7z'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_img.svg" v-else-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
                        <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
                        <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(file.fileFormat)" />
                        <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
                        <span class="text-indigo hover-line" @click="downloadFile({ fileId: file.id, belongId: id, fileSign: 'P01', fileName: file.fileOriginName })">{{ file.fileOriginName }}</span>
                      </div>
                    </div> -->
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right v-mt-md">
          <a-space>
            <a-button icon="rollback" type="default" @click="$router.go(-1)">返回</a-button>
          </a-space>
        </div>
      </div>
    </div>
    <a-modal :confirmLoading="reportUploading" :visible="reportModalVisible" :width="500" @cancel="cancelReportModal" centered title="上传文档">
      <template slot="footer">
        <a-space v-if="reportModalData.state !== 1">
          <a-button icon="close" @click="cancelReportModal">取消</a-button>
          <a-button type="primary" icon="check" @click="handleUploadReport" :loading="reportUploading">提交</a-button>
        </a-space>
        <a-button icon="close" @click="cancelReportModal" v-else>关闭</a-button>
      </template>
      <a-form autocomplete="off" class="form-dense" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" v-if="reportModalData.state === 1">
        <a-form-item>
          <span slot="label">文档类型</span>
          <span>{{ reportModalData.reportName }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">截止日期</span>
          <span>{{ reportModalData.limitDate }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">提交日期</span>
          <span>{{ reportModalData.submitDate }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">已上传文件</span>
          <file-container :files="reportModalData.files" :belongId="id" fileSign="P01" :imgSize="64" v-if="reportModalData.files && id"></file-container>
          <!-- <div class="file-list" v-if="reportModalData.files">
            <div class="file-item" v-for="file in reportModalData.files" :key="file.id">
              <img class="file-img" src="~@/assets/images/file_word.svg" v-if="['doc', 'docx', 'wps'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_excel.svg" v-else-if="file.fileFormat === 'xls' || file.fileFormat === 'xlsx'" />
              <img class="file-img" src="~@/assets/images/file_ppt.svg" v-else-if="file.fileFormat === 'ppt' || file.fileFormat === 'pptx'" />
              <img class="file-img" src="~@/assets/images/file_compress.svg" v-else-if="['rar', 'zip', '7z'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_img.svg" v-else-if="['jpg', 'jpeg', 'png', 'gif'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_txt.svg" v-else-if="file.fileFormat === 'txt'" />
              <img class="file-img" src="~@/assets/images/file_pdf.svg" v-else-if="file.fileFormat === 'pdf'" />
              <img class="file-img" src="~@/assets/images/file_video.svg" v-else-if="['mp4', 'wmv', 'rmvb', 'avi', 'mov', 'mkv'].includes(file.fileFormat)" />
              <img class="file-img" src="~@/assets/images/file_default.svg" v-else />
              <span class="text-indigo hover-line" @click="downloadFile({ fileId: file.id, belongId: id, fileSign: 'P01', fileName: file.fileOriginName })">{{ file.fileOriginName }}</span>
            </div>
          </div> -->
        </a-form-item>
      </a-form>
      <a-form autocomplete="off" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" v-else>
        <a-alert class="v-mb-sm" type="error" v-if="reportModalData.state === -1">
          <div slot="message" class="text-red">上次文档提交已被驳回，审核意见：{{ reportModalData.checkOpinion }}</div>
        </a-alert>
        <a-form-item class="form-item-dense">
          <span slot="label">文档类型</span>
          <span>{{ reportModalData.reportName }}</span>
        </a-form-item>
        <a-form-item class="form-item-dense">
          <span slot="label">截止日期</span>
          <span>{{ reportModalData.limitDate }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            <i class="required-mark">*</i>
            上传文件
          </span>
          <a-upload-dragger
            :action="`${baseURL}/file/upload`"
            :data="{
              params: JSON.stringify({ fileBelongId: id, fileType: '204', fileSign: 'P01' }),
            }"
            :show-upload-list="{
              showPreviewIcon: true,
              showRemoveIcon: true,
              showDownloadIcon: true,
            }"
            :file-list="reportFiles"
            :before-upload="beforeUploadFile"
            @change="handleUploadReportFile"
            @preview="handlePreviewFile"
            @download="({ fileId, name }) => downloadFile({ fileId, belongId: id, fileSign: 'P01', fileName: name })"
            accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .pdf, .rar, .zip, .ppt, .pptx, .xls, .xlsx"
          >
            <a-icon type="plus" />
            <p class="text-faded">添加文件</p>
          </a-upload-dragger>
        </a-form-item>
      </a-form>
    </a-modal>
    <a-modal :confirmLoading="changeSaving" :visible="changeModalVisible" :width="920" @cancel="changeModalVisible = false" @ok="handleSubmitChangeinfo" centered title="项目变更申请">
      <template slot="footer">
        <a-space>
          <a-button icon="close" @click="changeModalVisible = false">取消</a-button>
          <a-button type="primary" icon="check" @click="handleSubmitChangeinfo(false)" :loading="changeSaving">保存初稿</a-button>
          <a-button type="primary" icon="arrow-right" @click="handleSubmitChangeinfo(true)" :loading="changeSaving">
            <template v-if="changeModalForm.state === -1">重新</template>提交申请
          </a-button>
        </a-space>
      </template>
      <a-alert class="v-mb-sm" type="error" v-if="changeModalForm.state === -1">
        <div slot="message" class="text-red">上次项目变更申请已被驳回，审核意见：{{ changeModalForm.checkOpinion }}</div>
      </a-alert>
      <a-form autocomplete="off" :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
        <a-form-item class="form-item-dense">
          <span slot="label">申请人</span>
          <span>{{ userinfo.realName }}</span>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            <i class="required-mark">*</i>
            变更内容
          </span>
          <div class="text-left">
            <a-button size="small" type="primary" icon="plus" @click="handleMoreChangeDetails">添加变更明细</a-button>
          </div>
          <a-table class="list-table" size="small" :bordered="false" :pagination="false" :columns="changeModalCols" :data-source="changeModalForm.changeDetails" :scroll="{ x: '100%' }" rowKey="seq">
            <template slot="changeType" slot-scope="text, record, index">
              <a-select size="small" placeholder="选择变更项" v-model="record.changeType" style="width: 100%" allowClear :ref="`changeType${index}`" @change="value => handleChangeType(value, record)">
                <a-select-option v-for="item in changeTypes" :key="item.value === void 0 ? item.label : item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </template>
            <template slot="changeContent" slot-scope="text, record, index">
              <div v-if="record.changeType === '项目名称'">
                <div class="changeinfo-before">
                  <a-tag color="#3f51b5" class="tag-tiny">变更前</a-tag>
                  <div class="v-mt-xs">{{ record.before.projectName }}</div>
                </div>
                <div class="changeinfo-after v-mt-xs" v-if="record.after">
                  <a-tag color="red" class="tag-tiny">变更后</a-tag>
                  <div class="v-mt-xs"><a-input v-model="record.after.projectName" placeholder="项目名称" :ref="`changeProject${index}`" /></div>
                </div>
              </div>
              <div v-if="record.changeType === '项目周期'">
                <div class="changeinfo-before">
                  <a-tag color="#3f51b5" class="tag-tiny">变更前</a-tag>
                  <div class="v-mt-xs">
                    {{ record.before.startDate }}
                    <template>~ {{ record.before.endDate }}</template>
                  </div>
                </div>
                <div class="changeinfo-after v-mt-xs" v-if="record.after">
                  <a-tag color="red" class="tag-tiny">变更后</a-tag>
                  <div class="v-mt-xs"><a-range-picker v-model="record.after.dates" format="YYYY-MM-DD" value-format="YYYY-MM-DD" :placeholder="['开始时间', '结束时间']" :ref="`changePicker${index}`" style="width: 210px" /></div>
                </div>
              </div>
              <div v-if="record.changeType === '项目负责人'">
                <div class="changeinfo-before">
                  <a-tag color="#3f51b5" class="tag-tiny">变更前</a-tag>
                  <div class="v-mt-xs">{{ record.before.supervisor }}</div>
                </div>
                <div class="changeinfo-after v-mt-xs" v-if="record.after">
                  <a-tag color="red" class="tag-tiny">变更后</a-tag>
                  <div class="v-mt-xs"><a-input v-model="record.after.supervisor" placeholder="项目负责人" :ref="`changeSupervisor${index}`" style="width: 150px" /></div>
                </div>
              </div>
              <div v-if="record.changeType === '项目预算'">
                <div class="changeinfo-before">
                  <a-tag color="#3f51b5" class="tag-tiny">变更前</a-tag>
                  <div class="v-mt-xs">{{ $utils.thousands(record.before.applyAmount, 0) }}</div>
                </div>
                <div class="changeinfo-after v-mt-xs" v-if="record.after">
                  <a-tag color="red" class="tag-tiny">变更后</a-tag>
                  <div class="v-mt-xs"><a-input v-model="record.after.applyAmount" placeholder="输入金额" :ref="`changeAmount${index}`" style="width: 150px" suffix="元" /></div>
                </div>
              </div>
              <div v-if="record.changeType === '项目实施地点'">
                <div class="changeinfo-before">
                  <a-tag color="#3f51b5" class="tag-tiny">变更前</a-tag>
                  <div class="v-mt-xs">{{ $utils.flatAddress(record.before.addresses) }}</div>
                </div>
                <div class="changeinfo-after v-mt-xs" v-if="record.after">
                  <a-tag color="red" class="tag-tiny">变更后</a-tag>
                  <div class="">
                    <div class="flex v-mt-xs items-center" v-for="(item, idx) in record.after.addresses" :key="idx">
                      <div style="width: calc(100% - 60px)">
                        <a-input-group compact>
                          <a-cascader changeOnSelect style="width: 250px" expand-trigger="hover" :show-search="{ cascaderFilter }" :options="districts" placeholder="选择地区" v-model="item.districts" :ref="`changeDistrict${index}-${idx}`" />
                          <a-input style="width: calc(100% - 260px)" placeholder="详细地址" allowClear v-model="item.addr" :ref="`changeAddr${index}-${idx}`" />
                        </a-input-group>
                      </div>
                      <a-space>
                        <a-button size="small" type="primary" icon="plus" @click="onAddAddress(record.after, idx + 1)"></a-button>
                        <a-button size="small" type="default" icon="close" @click="onRemoveAddress(record.after, idx)" v-if="record.after.addresses.length > 1"></a-button>
                      </a-space>
                    </div>
                    <!-- <a-input-group compact>
                      <a-cascader style="width: 250px" expand-trigger="hover" :show-search="{ cascaderFilter }" :options="districts" placeholder="选择地区" v-model="record.after.districts" :ref="`changeDistrict${index}`" />
                      <a-input style="width: calc(100% - 250px)" placeholder="详细地址" allowClear v-model="record.after.addr" :ref="`changeAddr${index}`" />
                    </a-input-group> -->
                  </div>
                </div>
              </div>
            </template>
            <template slot="action" slot-scope="text, record, index">
              <a-space class="table-actions" :size="6">
                <span class="table-action text-red" title="删除" @click="changeModalForm.changeDetails.splice(index, 1)"><a-icon type="close"></a-icon></span>
              </a-space>
            </template>
          </a-table>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            <i class="required-mark">*</i>
            变更原因
          </span>
          <a-textarea :rows="4" v-model="changeModalForm.changeReason" style="width: 100%" ref="changeReason" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { getProject, listReports, uploadReport, saveChangeinfo, listChangeinfo, delChangeinfo } from '@/api/main'
import districts from '@/utils/districts'
export default {
  name: 'ProjectView',
  data() {
    return {
      fundedTab: '',
      currentStep: 0,
      id: +this.$route.query.id,
      data: {},
      showAllContent: false,
      reportLoading: false,
      reportUploading: false,
      reportModalVisible: false,
      reportModalData: {},
      reportFiles: [],
      reportCols1: [
        {
          title: '#',
          dataIndex: 'id',
          width: 50,
          align: 'center',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '文件类型',
          ellipsis: true,
          dataIndex: 'reportName',
          // scopedSlots: { customRender: 'rsName' },
        },
        {
          title: '提交人',
          ellipsis: true,
          dataIndex: 'reportDirection',
          width: 150,
          scopedSlots: { customRender: 'reportDirection' },
        },
        {
          title: '提交截止日期',
          dataIndex: 'limitDate',
          align: 'center',
          width: 100,
        },
        {
          title: '剩余期限',
          dataIndex: 'rest',
          width: 160,
          align: 'center',
          scopedSlots: { customRender: 'rest' },
        },
        {
          title: '文档状态',
          dataIndex: 'state',
          align: 'center',
          width: 110,
          scopedSlots: { customRender: 'state' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      reportList1: [],
      reportCols2: [
        {
          title: '#',
          dataIndex: 'id',
          width: 50,
          align: 'center',
          customRender: (text, record, index) => index + 1,
        },
        {
          title: '文件类型',
          ellipsis: true,
          width: 250,
          dataIndex: 'reportName',
          scopedSlots: { customRender: 'reportName' },
        },
        {
          title: '提交人',
          ellipsis: true,
          dataIndex: 'rsName2',
          width: 150,
          scopedSlots: { customRender: 'rsName' },
        },
        {
          title: '提交截止日期',
          dataIndex: 'limitDate',
          align: 'center',
          width: 100,
        },
        {
          title: '实际上传日期',
          dataIndex: 'submitDate',
          width: 100,
          align: 'center',
        },
        {
          title: '文件',
          dataIndex: 'files',
          scopedSlots: { customRender: 'files' },
        },
      ],
      reportList2: [],
      concludeTab: '',
      reportLoading2: false,
      unloadConcludeReportList: [],
      uploadedConcludeReportList: [],
      districts,
      changeList: [],
      changeModalVisible: false,
      changeModalForm: {
        id: null,
        changeReason: '',
        changeDetails: [],
      },
      changeModalCols: [
        // {
        //   title: '#',
        //   dataIndex: 'id',
        //   width: 50,
        //   align: 'center',
        //   customRender: (text, record, index) => index + 1,
        // },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 50,
          scopedSlots: { customRender: 'action' },
          customCell: () => ({
            style: {
              'vertical-align': 'top',
            },
          }),
        },
        {
          title: '变更类型',
          ellipsis: true,
          width: 140,
          dataIndex: 'changeType',
          scopedSlots: { customRender: 'changeType' },
          customCell: () => ({
            style: {
              'vertical-align': 'top',
            },
          }),
        },
        {
          title: '变更内容',
          dataIndex: 'changeContent',
          scopedSlots: { customRender: 'changeContent' },
        },
      ],
      changeCols: [
        {
          title: '变更类型',
          ellipsis: true,
          align: 'center',
          width: 120,
          dataIndex: 'changeType',
        },
        {
          title: '变更前',
          dataIndex: 'before',
          scopedSlots: { customRender: 'before' },
          customCell: () => ({
            style: {
              // background: 'rgba(232, 234, 246, 0.4)',
              color: 'rgba(0, 0, 0, 0.5)',
            },
          }),
        },
        {
          title: '变更后',
          dataIndex: 'after',
          scopedSlots: { customRender: 'after' },
        },
      ],
      changeLoading: false,
      changeSaving: false,
    }
  },
  computed: {
    changeTypes: vm => vm.$store.state.app.changeTypes,
  },
  watch: {
    $route() {
      this.id = +this.$route.query.id
      this.load()
    },
    fundedTab(tab) {
      if (tab === 'changeinfo') {
        this.loadChangeinfo()
      } else {
        this.loadReportList()
      }
    },
    concludeTab() {
      this.loadReportList(true)
    },
  },
  methods: {
    load() {
      let id = +this.$route.query.id
      getProject(id).then(({ data }) => {
        if (data.success) {
          let result = data.result
          let { files, state } = result
          result.$201Files = files.filter(file => file.fileType === '201')
          result.$202Files = files.filter(file => file.fileType === '202')
          this.currentStep = state - 1
          this.data = result
          if (state >= 5) {
            this.fundedTab = state === 5 ? 'unload' : 'loaded'
          }
          if (state >= 6) {
            this.concludeTab = state === 6 ? 'unload' : 'loaded'
          }
        }
      })
    },
    loadReportList(conclude = false) {
      if (conclude) {
        this.reportLoading2 = true
      } else {
        this.reportLoading = true
      }
      listReports(this.id).then(({ data }) => {
        if (data.success) {
          let list = data.result
          list.forEach((item, key) => {
            item.key = key
          })
          this.reportList1 = list.filter(item => item.state !== 2 && item.reportNode === 1).sort((a, b) => a.state - b.state || a.restDays - b.restDays)
          this.reportList2 = list.filter(item => item.state === 2 && item.reportNode === 1)
          this.unloadConcludeReportList = list.filter(item => item.state !== 2 && item.reportNode === 2).sort((a, b) => b.state - a.state || a.restDays - b.restDays)
          this.uploadedConcludeReportList = list.filter(item => item.state === 2 && item.reportNode === 2)
        }
        if (conclude) {
          this.reportLoading2 = false
        } else {
          this.reportLoading = false
        }
      })
    },
    onChange(current) {
      console.log('onChange:', current)
    },
    onAddAddress(item, index = 0) {
      let len = item.addresses.length
      item.addresses.splice(index, 0, {
        seq: len + 1,
        districts: [],
        addr: '',
      })
    },
    onRemoveAddress(item, index) {
      item.addresses.splice(index, 1)
    },
    handleUploadReportFile(info) {
      this.reportFiles = this.handleUploadFile(info, -1)
    },
    handleConcludeTabChange() {
      this.loadReportList(true)
    },
    showReportModal(data) {
      this.reportModalData = { ...data }
      this.reportFiles = []
      this.reportModalVisible = true
    },
    cancelReportModal() {
      this.reportModalVisible = false
      this.reportFiles = []
    },
    handleUploadReport() {
      let fileIds = this.reportFiles.map(file => file.fileId)
      this.$message.destroy()
      if (fileIds.length === 0) {
        this.$message.warning('请添加上传文件')
        return
      }
      let id = this.reportModalData.id
      this.reportUploading == true
      let params = {
        id,
        projectId: this.id,
        fileIds: fileIds.join(','),
      }
      uploadReport(params)
        .then(({ data }) => {
          if (data.success) {
            this.$message.success('提交成功')
            this.cancelReportModal()
            this.loadReportList()
          }
          this.reportUploading = false
        })
        .catch(() => {
          this.reportUploading = false
        })
    },
    handleOpenChangeModal() {
      this.changeModalForm = {
        id: null,
        changeReason: '',
        changeDetails: [],
      }
      this.changeModalVisible = true
    },
    handleMoreChangeDetails() {
      let len = this.changeModalForm.changeDetails.length
      this.changeModalForm.changeDetails.push({
        seq: len + 1,
        changeType: undefined,
        before: {},
        after: {},
      })
    },
    handleChangeType(changeType, record) {
      if (changeType === '项目名称') {
        record.before = {
          projectName: this.data.projectName,
        }
        record.after = {
          projectName: '',
        }
      } else if (changeType === '项目负责人') {
        record.before = {
          supervisor: this.data.supervisor,
        }
        record.after = {
          supervisor: '',
        }
      } else if (changeType === '项目周期') {
        record.before = {
          startDate: this.data.startDate,
          endDate: this.data.endDate,
        }
        record.after = {
          startDate: undefined,
          endDate: undefined,
          dates: [],
        }
      } else if (changeType === '项目预算') {
        record.before = {
          applyAmount: this.data.applyAmount,
        }
        record.after = {
          applyAmount: '',
        }
      } else if (changeType === '项目实施地点') {
        record.before = {
          addresses: this.data.addresses.map(({ province, city, district, addr }) => {
            return {
              province,
              city,
              district,
              addr,
            }
          }),
        }
        record.after = {
          addresses: [
            {
              districts: [],
              addr: '',
            },
          ],
        }
      }
      console.log(changeType, record)
    },
    handleEditChangeinfo(record) {
      let { id, changeReason, changeContent, state, checkOpinion } = record
      let form = {
        id,
        state,
        changeReason,
        checkOpinion,
        changeDetails: JSON.parse(changeContent || '[]'),
      }
      form.changeDetails.forEach((item, index) => {
        let {
          changeType,
          after: { startDate, endDate, addresses },
        } = item
        item.seq = index + 1
        if (changeType === '项目实施地点') {
          item.after.addresses = addresses.map(({ province, city, district, addr }) => {
            let districts = []
            if (province) {
              districts.push(province)
            }
            if (city) {
              districts.push(city)
            }
            if (district) {
              districts.push(district)
            }
            return {
              districts,
              addr,
            }
          })
        } else if (changeType === '项目周期') {
          item.after.dates = [startDate, endDate]
        }
      })
      console.log('edit', form)
      this.changeModalForm = form
      this.changeModalVisible = true
    },
    loadChangeinfo() {
      this.changeLoading = true
      listChangeinfo(this.id).then(({ data }) => {
        if (data.success) {
          let list = data.result.map(item => ({ ...item, changeDetails: JSON.parse(item.changeContent || '[]').map((d, key) => ({ key, ...d })) }))
          if (this.currentStep > 4) {
            list.sort((a, b) => b.state - a.state)
          }
          this.changeList = list
        }
        this.changeLoading = false
      })
    },
    handleSubmitChangeinfo(check) {
      let { id, changeReason, changeDetails, state } = this.changeModalForm
      // console.log(changeDetails)
      this.$message.destroy()
      if (changeDetails.length === 0) {
        this.$message.warning('请添加变更明细')
        return
      }
      let validated = true
      for (let index = 0; index < changeDetails.length; index++) {
        if (!validated) {
          break
        }
        let {
          changeType,
          after: { projectName, supervisor, applyAmount, dates, addresses },
        } = changeDetails[index]
        if (!changeType) {
          this.$message.warning('请选择变更类型')
          this.$refs[`changeType${index}`].focus()
          return
        }
        if (changeType === '项目名称') {
          if (projectName.trim() === '') {
            this.$message.warning('请输入项目名称')
            this.$refs[`changeProject${index}`].focus()
            validated = false
          }
        } else if (changeType === '项目负责人') {
          if (supervisor.trim() === '') {
            this.$message.warning('请输入项目负责人姓名')
            this.$refs[`changeSupervisor${index}`].focus()
            validated = false
          }
        } else if (changeType === '项目预算') {
          if (!this.$utils.isNum(applyAmount)) {
            this.$message.warning('请输入项目预算金额')
            this.$refs[`changeAmount${index}`].focus()
            validated = false
          }
        } else if (changeType === '项目周期') {
          if (dates.length === 0) {
            this.$message.warning('请选择项目周期')
            this.$refs[`changePicker${index}`].focus()
            validated = false
          }
        } else if (changeType === '项目实施地点') {
          addresses = addresses.filter(item => item.districts[0])
          if (addresses.length === 0) {
            this.$message.warning('请选择实施地区')
            if (this.$refs[`changeDistrict${index}-0`] && this.$refs[`changeDistrict${index}-0`][0]) {
              this.$refs[`changeDistrict${index}-0`][0].focus()
            }
            validated = false
          }
        }
      }
      if (!validated) {
        return
      }
      if (changeReason.trim() === '') {
        this.$message.warning('请输入变更原因')
        this.$refs.changeReason.focus()
        return
      }
      let details = changeDetails.map(({ changeType, before, after: { projectName, supervisor, applyAmount, dates, addresses } }) => {
        let detail = {
          changeType,
          before,
        }
        if (changeType === '项目名称') {
          detail.after = {
            projectName,
          }
        } else if (changeType === '项目负责人') {
          detail.after = {
            supervisor,
          }
        } else if (changeType === '项目预算') {
          detail.after = {
            applyAmount,
          }
        } else if (changeType === '项目周期') {
          detail.after = {
            startDate: dates[0],
            endDate: dates[1],
          }
        } else if (changeType === '项目实施地点') {
          addresses = addresses.filter(item => item.districts[0])
          detail.after = {
            addresses: addresses.map(({ districts, addr }) => ({ province: districts[0], city: districts[1], district: districts[2], addr })),
          }
        }
        return detail
      })
      let params = {
        id,
        check,
        state: check ? 1 : id ? state : 0,
        projectId: this.id,
        changeReason: changeReason.trim(),
        changeContent: JSON.stringify(details),
      }
      this.changeSaving = true
      saveChangeinfo(params).then(({ data }) => {
        if (data.success) {
          this.$message.success('操作成功')
          this.changeModalVisible = false
          this.loadChangeinfo()
        }
        this.changeSaving = false
      })
    },
  },
  mounted() {
    this.load()
  },
}
</script>

<style lang="stylus">
.stage-section {
  position: relative;
  display: flex;
  align-items: stretch;
  border-radius: 4px;
  padding: 3px;
  background: #f4f2fa;
  align-content: stretch;
  min-height: 80px;
  .stage-header {
    position: relative;
    min-width: 160px;
    width: 210px;
    font-size: 24px;
    text-align: center;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f40000;
    span {
      margin-left: 12px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 68px;
      top: -84px;
      left: calc(50% - 2px);
      border-left: 4px dashed #e2e0e8;
      // background: rgba(102, 93, 105, 0.2);
    }
    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: -16px;
      left: calc(50% - 12px);
      border: 12px solid transparent;
      border-top-color: #e2e0e8;
    }
    // margin-right: auto;
  }
  &:first-child {
    .stage-header:before,
    .stage-header:after {
      content: none;
    }
  }
  &.done {
    .stage-header {
      color: #3f51b5;

      &:after {
        border-left-style: solid;
      }
    }
  }
  .stage-data {
    background: #fff;
    width: 0;
    flex: 10000 1 0%;

    .el-tabs__header {
      margin-bottom: 2px;
    }
    .el-tabs__nav-wrap {
      padding-left: 10px;
    }
  }

  .table-container {
    margin-bottom: 0;
  }

  & + & {
    margin-top: 80px;
  }
}
</style>
